import { useState } from 'react';
import { useSubmit } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/alert';
import Button from '~/components/button';
import Card from '~/components/card';
import LineItem from '~/components/line-item';
import LinkButton from '~/components/link-button';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '~/components/modal';
import Page from '~/components/page';
import { ClaimType, ResolutionMethodLabels } from '~/utils/constants';
import { formatDate } from '~/utils/formatters';
import { FormKeys, useOrderClaimActionResult } from './order-claim-action';
import { OrderClaimLoaderData, useOrderClaimData } from './order-claim-loader';

type LineItem = OrderClaimLoaderData['lineItems'][number];
// ----------- utils ----------------------
const getFootnote = (item: LineItem) => {
  switch (true) {
    case item.return.isFinalSale:
      return 'Final Sale';
    case item.remainingReturnableQuantity === 0 || !item.warranty.isEligible:
      return 'Not Eligible';
    default:
      return '';
  }
};

function Footnote({ children }: { children: string }) {
  return <span className="text-red-700">{children}</span>;
}

const isPastActionDate = (lastDate?: string) =>
  lastDate ? new Date(lastDate) < new Date() : false;

const getHeadlineText = (
  claimType: ClaimType,
  pendingClaims: number,
  lastActionableDate?: string,
) => {
  switch (true) {
    case isPastActionDate(lastActionableDate):
      return {
        text: `This order is no longer eligible`,
        showHeadline: true,
      };

    case pendingClaims < 1:
      return {
        text: `To get started, select an eligible product for ${claimType.toLowerCase()}`,
        showHeadline: false,
      };

    default:
      return {
        text: 'When ready, continue to review your request',
        showHeadline: false,
      };
  }
};

// ----------- styles ----------------------
const actionStyles = 'w-full sm:w-52';

const lineItemStyles =
  'border-b border-outline mt-4 pb-4 last:border-none last:pb-0';

// ----------- components ----------------------
function NewClaimAction({
  item,
  claimType,
}: {
  item: LineItem;
  claimType: ClaimType;
}) {
  const submit = useSubmit();
  const select = () => {
    // submit the whole item the action has the computed available quantity on the item
    submit(
      {
        [FormKeys.claimType]: claimType,
        [FormKeys.lineItem]: JSON.stringify(item),
      },
      { method: 'post' },
    );
  };
  return (
    <Button variant="outlined" className={actionStyles} onClick={select}>
      Select
    </Button>
  );
}

function CancelClaimAction({
  id,
  resolutionMethod,
  exchangeItem,
}: {
  id: string;
  resolutionMethod?: string;
  exchangeItem?: string;
}) {
  const [open, setOpen] = useState(false);
  const submit = useSubmit();
  const confirm = () => {
    submit({ [FormKeys.claimId]: id }, { method: 'delete' });
    setOpen(false);
  };

  return (
    <div className={twMerge(actionStyles, 'flex flex-col')}>
      <Button
        className="border-red-700 font-semibold text-red-700 focus:ring-red-700 sm:w-52"
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        Cancel
      </Button>

      {resolutionMethod && (
        <span className="mt-2 text-center text-xs text-gray-500">
          {resolutionMethod}
          {exchangeItem && (
            <>
              <span> for </span>
              <span>{exchangeItem}</span>
            </>
          )}
        </span>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalHeader>
          <ModalTitle>Are you sure you want to cancel this request?</ModalTitle>
        </ModalHeader>
        <p className="text-sm">
          Canceling will remove this request. You can still create another
          request for this item after you cancel.
        </p>
        <ModalFooter>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            No, Keep Request
          </Button>
          <Button onClick={confirm}>Yes, Cancel Request</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

function LineItems({
  items,
  claimType,
  lastDate,
}: {
  items: OrderClaimLoaderData['lineItems'];
  claimType: ClaimType;
  lastDate?: string;
}) {
  const headline =
    lastDate ?
      `Eligible for ${claimType.toLocaleLowerCase()} until ${formatDate(
        lastDate,
      )}`
    : `Eligible for ${claimType.toLocaleLowerCase()}`;

  if (items.length === 0) {
    return null;
  }

  return (
    <Card headline={headline}>
      {items.map((item) => (
        <LineItem
          className={lineItemStyles}
          key={item.id}
          name={item.name ?? ''}
          imgSrc={item.imgUrl}
          price={item.unitPriceDisplay}
          quantity={item.quantity}
          remainingReturnableQuantity={item.remainingReturnableQuantity}
          options={item.optionsFromPlatform}
          footnote={
            claimType === ClaimType.return &&
            item.return.isExchangeOnly && <Footnote>Exchange Only</Footnote>
          }
        >
          <NewClaimAction item={item} claimType={claimType} />
        </LineItem>
      ))}
    </Card>
  );
}

function ReturningItems({
  claims,
}: {
  claims: OrderClaimLoaderData['pendingClaims'];
}) {
  if (claims.length === 0) {
    return null;
  }

  return (
    <Card headline="Ready to submit">
      {claims.map(
        ({
          id,
          requestedResolutionMethodEnum,
          lineItem,
          quantity,
          variant,
        }) => (
          <LineItem
            className={lineItemStyles}
            key={id}
            name={lineItem.name ?? ''}
            imgSrc={lineItem.imgUrl}
            price={lineItem.unitPriceDisplay}
            quantity={quantity}
            options={lineItem.optionsFromPlatform}
          >
            <CancelClaimAction
              id={id}
              resolutionMethod={
                ResolutionMethodLabels[requestedResolutionMethodEnum]
              }
              exchangeItem={variant?.label}
            />
          </LineItem>
        ),
      )}
    </Card>
  );
}

function IneligibleItems({
  items,
  claimType,
  lastDate,
}: {
  items: OrderClaimLoaderData['ineligibleItems'];
  claimType: ClaimType;
  lastDate?: string;
}) {
  if (items.length === 0) {
    return null;
  }

  const headlineText =
    isPastActionDate(lastDate) && lastDate ?
      `${claimType} period ended on ${formatDate(lastDate)}`
    : `Ineligible for ${claimType.toLowerCase()}`;

  return (
    <Card headline={headlineText}>
      {items.map((item) => (
        <LineItem
          className={`${lineItemStyles} opacity-60`}
          key={item.id}
          name={item.name ?? ''}
          imgSrc={item.imgUrl}
          price={item.unitPriceDisplay}
          quantity={item.quantity}
          remainingReturnableQuantity={item.remainingReturnableQuantity}
          options={item.optionsFromPlatform}
          footnote={<Footnote>{getFootnote(item)}</Footnote>}
        />
      ))}
    </Card>
  );
}

export default function OrderClaim() {
  const {
    claimType,
    lineItems,
    pendingClaims,
    ineligibleItems,
    lastActionableDate,
  } = useOrderClaimData();

  const actionResult = useOrderClaimActionResult();

  const headline = getHeadlineText(
    claimType,
    pendingClaims.length,
    lastActionableDate,
  );

  return (
    <Page headline={headline.text} showHeadline={headline.showHeadline}>
      <div className="flex flex-col gap-4">
        <LineItems
          items={lineItems}
          claimType={claimType}
          lastDate={lastActionableDate}
        />
        <ReturningItems claims={pendingClaims} />
        <IneligibleItems
          items={ineligibleItems}
          claimType={claimType}
          lastDate={lastActionableDate}
        />
        {pendingClaims.length > 0 && (
          <LinkButton
            variant="filled"
            className="mt-2 w-full md:w-1/2"
            to="../refund-options"
            relative="path"
          >
            Next
          </LinkButton>
        )}
        {actionResult?.ok === false && (
          <Alert variant="error" title="Problem completing your action.">
            <p>{actionResult.message}</p>
          </Alert>
        )}
      </div>
    </Page>
  );
}
