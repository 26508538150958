import { z } from 'zod';
import api from '~/utils/api';
import { computeCost } from '~/utils/compute';
import { RollupStatusCode } from '~/utils/constants';
import { formatDate, formatDisplayValue, toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { AsyncReturnType, CrewContext, Simplify } from '~/utils/types';

type Claim = AsyncReturnType<typeof api.fetchCrewClaim>;
type Shipment = NonNullable<Claim['claimReturnShipments']>[number];
type ClaimLineItem = Claim['claimLineItems'][number];
type ClaimLineItemWithCost = ClaimLineItem & {
  cost: string;
};

type VariantExchange = NonNullable<ClaimLineItem['variantExchangeLineItem']>;

type ReturnShipment = Simplify<
  Omit<NonNullable<Claim['claimReturnShipments']>[number], 'claimLineItems'> & {
    claimLineItems: ClaimLineItemWithCost[];
    returnByDate: string;
    isExpired: boolean;
    headline: string;
    title: string;
    downloadLabel: string;
  }
>;

type FormattedCurrency = ReturnType<
  ReturnType<typeof formatDisplayValue<number>>
>;
type FormattedResolutionSummary = Simplify<
  Omit<Required<Claim>['resolutionSummary'], 'refund' | 'giftCard'> & {
    refund?: {
      amount: FormattedCurrency;
      handlingFee: FormattedCurrency;
      shippingFee: FormattedCurrency;
    };
    giftCard?: {
      amount: FormattedCurrency;
      incentiveAmount: FormattedCurrency;
    };
  }
>;

export type ClaimOverviewData = {
  storeUrl?: string;
  claimExternalId: Claim['externalId'];
  claimRollup: Claim['claimRollup'];
  greenReturns?: {
    items: ClaimLineItemWithCost[];
    title: string;
    headline: string;
  };
  requestSummary?: {
    variantExchangeLineItems: VariantExchange[];
  };
  hideLabelDownloadWhenQrCodeIsPresent: boolean;
  returnShipments: ReturnShipment[];
  resolutionSummary?: FormattedResolutionSummary;
  noteToCustomer?: Claim['noteToCustomer'];
  isCompleted: boolean;
};

const getClaim = (context: CrewContext, claimExternalId: string) =>
  context.claim && context.claim.externalId === claimExternalId ?
    Promise.resolve(context.claim)
  : api
      .fetchCrewClaim({
        params: {
          storefrontId: context.settings.storefrontId,
          claimExternalId,
        },
      })
      .then(context.setClaim);

const formatResolutionSummary = (claim: Claim) => {
  const formatCurrency = formatDisplayValue(
    toCurrency(claim.originalStoreOrder.currencyCode),
  );

  const { refund, giftCard, ...summary } = claim.resolutionSummary ?? {};

  return (
    claim.resolutionSummary &&
    ({
      ...summary,

      ...(refund && {
        refund: {
          amount: formatCurrency(refund.amount),
          handlingFee: formatCurrency(refund.handlingFee),
          shippingFee: formatCurrency(refund.shippingFee),
        },
      }),

      ...(giftCard && {
        giftCard: {
          amount: formatCurrency(giftCard.amount),
          incentiveAmount: formatCurrency(giftCard.incentiveAmount),
        },
      }),
    } satisfies FormattedResolutionSummary)
  );
};

const getReturnByDate = ({
  shipment,
  isInstantExchange,
  customerPayment,
}: {
  shipment: Shipment;
  isInstantExchange: boolean;
  customerPayment: Claim['customerPayment'];
}) => {
  const paymentExpiresOn =
    customerPayment?.authorizationExpiresOn ?? customerPayment?.capturedOn;

  return isInstantExchange && paymentExpiresOn ? paymentExpiresOn : (
      shipment.shipmentExpiresOn
    );
};

const getReturnByText = (returnByDate: string, isExpired: boolean) =>
  `${isExpired ? 'Return Expired On' : 'Return Items By'}: ${formatDate(
    returnByDate,
  )}`;

const getReturnShipmentHeadline = ({
  shipmentInTransit,
  status,
  returnByDate,
  isReturnByDateExpired,
}: {
  shipmentInTransit: boolean;
  status: Claim['claimRollup']['code'];
  returnByDate: string;
  isReturnByDateExpired: boolean;
}) => {
  if (shipmentInTransit) {
    return 'Items you returned';
  }

  if (returnByDate) {
    return getReturnByText(returnByDate, isReturnByDateExpired);
  }

  const statusHeadline = {
    [RollupStatusCode.inProgress]: 'Next Steps',
    [RollupStatusCode.readyForReview]: 'We are reviewing your information',
    [RollupStatusCode.completed]: 'We have reviewed your request',
  } satisfies Record<RollupStatusCode, string>;

  return statusHeadline[status];
};

const getReturnShipmentTypeText = (shipment: Shipment) => {
  if (shipment.returnShipmentType === 'Label' && shipment.qrCodeDetail) {
    return 'QR Code';
  }

  if (shipment.returnShipmentType === 'Packing_Slip') {
    return 'packing slip';
  }

  return 'label';
};

const getShipmentNotInTransitText = (
  shipment: Shipment,
  isInstantExchange: boolean,
) =>
  `Please use the provided ${getReturnShipmentTypeText(
    shipment,
  )} to return your items${
    isInstantExchange ?
      '. Because you selected an instant exchange, your new order will be processed immediately.'
    : ''
  }`;

const getReturnShipmentTitle = ({
  claim: {
    isInstantExchange,
    claimRollup: { code: status },
  },
  shipment,
  isExpired,
}: {
  claim: Claim;
  shipment: Shipment;
  isExpired: boolean;
}) => {
  if (isExpired && status !== 'Completed') {
    return 'Your return has expired';
  }

  if (!shipment.inTransitOn) {
    return getShipmentNotInTransitText(shipment, isInstantExchange);
  }

  const statusTitles = {
    [RollupStatusCode.inProgress]: `Your return is in progress`,
    [RollupStatusCode.readyForReview]: 'Your return is in-review',
    [RollupStatusCode.completed]: 'Your return is complete',
  } satisfies Record<RollupStatusCode, string>;

  return statusTitles[status];
};

const addCostToLineItem =
  (formatCurrency: (v: number) => string) => (lineItem: ClaimLineItem) =>
    ({
      ...lineItem,
      cost: formatCurrency(
        computeCost({
          unitPrice: lineItem.originalStoreOrderLineItem.unitPrice, // ? should this be the unitPrice from the claimLineItem?
          quantity: lineItem.quantity,
        }),
      ),
    }) satisfies ClaimLineItemWithCost;

const downloadLabels = {
  Label: 'Download Return Label',
  Packing_Slip: 'Download Packing Slip',
} satisfies Record<Shipment['returnShipmentType'], string>;

const formatShipment = (claim: Claim) => {
  const formatCurrency = toCurrency(claim.originalStoreOrder.currencyCode);

  return (shipment: Shipment) => {
    // we only show "return shipments" here
    // shipments where the customer is returning the items
    if (!shipment.isReturnShipment) return null;

    const returnByDate = getReturnByDate({
      shipment,
      isInstantExchange: claim.isInstantExchange,
      customerPayment: claim.customerPayment,
    });
    const isExpired =
      !!returnByDate && Date.now() >= new Date(returnByDate).getTime();

    return {
      ...shipment,
      returnByDate,
      isExpired,
      headline: getReturnShipmentHeadline({
        shipmentInTransit: !!shipment.inTransitOn,
        status: claim.claimRollup.code,
        returnByDate,
        isReturnByDateExpired: isExpired,
      }),
      title: getReturnShipmentTitle({
        claim,
        shipment,
        isExpired,
      }),
      claimLineItems: shipment.claimLineItems.map(
        addCostToLineItem(formatCurrency),
      ),
      downloadLabel: downloadLabels[shipment.returnShipmentType],
    } satisfies ReturnShipment;
  };
};

// add refund / gift card someday
const formatRequestSummary = (claim: Claim) => {
  const variantExchangeLineItems = claim.claimLineItems
    .map((lineItem) => lineItem.variantExchangeLineItem)
    .filter(Boolean);

  if (!variantExchangeLineItems.length) return undefined;

  return {
    variantExchangeLineItems,
  };
};

type Settings = AsyncReturnType<typeof api.fetchSettings>;
const getGreenReturns = (claim: Claim, theme: Settings['theme']) => {
  const formatCurrency = toCurrency(claim.originalStoreOrder.currencyCode);
  const returnItemIds = new Set(
    (claim.claimReturnShipments ?? []).flatMap((shipment) =>
      shipment.claimLineItems.map((lineItem) => lineItem.id),
    ),
  );

  return {
    title:
      claim.claimType === 'Return' ?
        (theme.noReturnRequiredItemsDetailText ??
        'You do not need to return these items.')
      : (theme.warrantyItemsDetailText ??
        'We will be in touch soon regarding your warranty claim.'),
    headline:
      claim.claimType === 'Return' ?
        (theme.noReturnRequiredItemsHeadline ?? 'Items not required to return')
      : 'Items under warranty',
    items: claim.claimLineItems
      .filter((lineItem) => !returnItemIds.has(lineItem.id))
      .map(addCostToLineItem(formatCurrency)),
  } satisfies ClaimOverviewData['greenReturns'];
};

const paramsSchema = z.object({
  claimExternalId: z.string(),
  store: z.string().default(''),
});

export default crewLoader(({ params, context }) => {
  const { claimExternalId, store } = paramsSchema.parse(params);

  return getClaim(context, claimExternalId)
    .then((claim) =>
      json<ClaimOverviewData>({
        storeUrl: context.settings.storeUrl,
        claimExternalId: claim.externalId,
        claimRollup: claim.claimRollup,
        noteToCustomer: claim.noteToCustomer,
        greenReturns: getGreenReturns(claim, context.settings.theme),
        resolutionSummary: formatResolutionSummary(claim),
        requestSummary: formatRequestSummary(claim),
        hideLabelDownloadWhenQrCodeIsPresent:
          context.settings.theme.hideLabelDownloadWhenQrCodeIsPresent ?? false,
        isCompleted: claim.claimRollup.code === RollupStatusCode.completed,
        returnShipments: (claim.claimReturnShipments ?? [])
          .map(formatShipment(claim))
          .filter(Boolean)
          .sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1)),
      }),
    )
    .catch(() => redirect(`/${store}`));
});

export const useClaimOverviewData = createLoaderDataHook<ClaimOverviewData>();
